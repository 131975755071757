.App {
  text-align: center;
  background-color: #0F0F0F;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
}


.border { /* For testing */
  border: 1px solid #eaeaea;
}

.font_space {
  font-family: 'Space Grotesk', sans-serif;
}

.font_clash {
  font-family: 'Clash Display', sans-serif;
  /* font-weight: 600; */
  font-style: normal;
  line-height: normal;
}

.button_shadow_black {
  box-shadow: 9px 6px 0px 0px rgba(0, 0, 0, 1), 9px 5.5px 0px 1px #fff;
  -webkit-box-shadow: 9px 6px 0px 0px rgba(0, 0, 0, 1), 9px 5.5px 0px 1px #fff;
  -moz-box-shadow: 9px 6px 0px 0px rgba(0, 0, 0, 1), 9px 5.5px 0px 1px #fff;
}

.slide:active {
  transform: translate(7px, 7px);
}

.slide_lite:active {
  transform: translate(2px, 2px);
}